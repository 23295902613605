"use client"; // Error components must be Client Components

import {
  appRouterSsrErrorHandler,
  AppRouterErrorProps,
} from "@highlight-run/next/ssr";

import { RotateCw } from "lucide-react";

import { Button } from "@/components/ui/button";

export default appRouterSsrErrorHandler(({ reset }: AppRouterErrorProps) => {
  return (
    <div className="flex h-screen flex-col items-center justify-center gap-8">
      <h2 className="scroll-m-20 text-xl font-semibold tracking-tight">
        Er is een fout opgetreden!
      </h2>
      <Button onClick={reset}>
        <RotateCw />
        Probeer te herstellen
      </Button>
    </div>
  );
});
